<template>
  <div class="shareAct" :style="tackBack()">
    <div class="logo">
      <img
      :class="picName !==''&& ( picName=='nmwf'? 'w248':picName=='recharge-hbsm'? 'w112': picName=='v10mogul'? 'w114': picName=='hfmogul'? 'w152': picName=='klxq'? 'w327':picName=='happychange' || picName=='v10noble' || picName== 'v10boss'|| picName== 'nobleplayer' ||picName=='dreamskin'||picName=='happyplanet'? 'w360': picName=='crazy'||picName=='qmlfl'|| isCommonBtn || isShowImg3||picName=='fls' ||picName=='ttlpf'?'w180' :picName=='cjyd'||picName=='wzzs'?'h300':picName == 'fanfan' || picName == 'mflp'|| picName == 'v10wj'|| picName == 'hfdr'|| picName == 'dswj'|| picName == 'yxhz'? 'w186' :picName == 'ttlp' ||picName == 'yjhp'? 'h168':picName == 'xylpf'?'w188':picName=='luckycat'?'w225':'')"
      :src="picName !==''? 'https://ad-static-xg.tagtic.cn/'+ imgBaseUrl +'/SDKL666-'+picName+'-2.png':require('../../static/img/logo.png')" alt="">
    </div>
    <div class="imgs"
      :class="picName !==''&&( picName=='yxhz'||picName=='dswj'||picName=='xylpf'||picName=='yjhp'||picName=='ttlpf'||picName=='hfdr'||picName=='ttlp'||picName=='v10wj'||picName=='luckycat'||picName=='mflp'||picName=='fanfan'||picName=='crazy' || picName=='happychange'?'hnone':picName=='recharge-hbsm'? 'w708': picName=='v10mogul'? 'w594':picName=='v10noble' || picName== 'v10boss'|| picName== 'nobleplayer' || picName=='dreamskin'||picName=='happyplanet' ? 'h800':'')">
      <img
        v-if="(picName !== 'hfmogul' && picName !== 'v10noble' && picName!== 'v10boss'&& picName!== 'dreamskin' && picName!== 'happyplanet' && picName !== 'cjyd' && picName !== 'wzzs' && picName !== 'fls'&& picName !== 'qmlfl' && !isShowImg3 && !isCommonBtn)"
        :src="picName !==''? 'https://ad-static-xg.tagtic.cn/'+ imgBaseUrl +'/SDKL666-'+picName+'-3.png':require('../../static/img/icon.png')" alt="">
    </div>
    <div class="btn"
      :class="picName=='hfmogul'? 'mt200' :  picName == 'v10noble' || picName== 'v10boss' || picName=='dreamskin' ||picName=='happyplanet'? 'btn-style': picName == 'nobleplayer'?'btn-style1': picName == 'crazy'?'btn-crazy':picName== 'happychange'?'btn-happy':picName =='fanfan'?'fanfan':picName=='mflp'?'mflp': picName == 'v10wj'?'v10wj':picName=='ttlp'?'ttlp':picName=='hfdr'?'hfdr': picName=='ttlpf'||picName == 'fls'?'ttlpf': picName=='cjyd'? 'cjyd':picName == 'yjhp'?'yjhp': picName == 'wzzs'||isShowImg3||picName == 'qmlfl'?'wzzs':picName == 'xylpf'?'xylpf':picName == 'luckycat'?'luckycat':picName === 'dswj'?'dswj':picName === 'yxhz' ?'yxhz' : isCommonBtn ? 'commonBtn' : ''"
      @click="gotoApk">
      {{isApprentice==='1'? '点击安装':'打开APP'}}</div>
    <Wxpopup v-if="isWeixin" />
    <img class="load" src="../../static/img/loading.gif" v-if="loading" alt="">
  </div>
</template>
<script>
import Wxpopup from '../components/Wxpopup'
// import ajax from '../service/index'
export default {
  data () {
    return {
      apk: '',
      isWeixin: false,
      isApprentice: '2',
      user_id: '',
      invite_code: '',
      paramsHref: '',
      loading: false,
      picName: '',
      package: '',
      isHave: true,
      wangzhuan: ['hpyd', 'wzpf', 'cjzs', 'pfdr', 'pfhz'], // 图片目录调整
      wangzhuanCommon: ['mfhp', 'yjhp', 'xys', 'yxyd', 'ttlpf', 'kjwj', 'pw', 'pdd', 'qbn', 'dyjc'], // 图片目录调整
      showImg: []
    }
  },
  components: {
    Wxpopup
  },
  computed: {
    imgBaseUrl () {
      return [...this.wangzhuan, ...this.wangzhuanCommon].includes(this.picName) ? 'wangzhuan' : 'ad-material'
    },
    isShowImg3 () {
      return this.wangzhuan.includes(this.picName)
    },
    isCommonBtn () {
      return this.wangzhuanCommon.includes(this.picName)
    }
  },
  created () {
    console.log(this.imgBaseUrl)
    const url = location.href
    console.log('Appurl', url)
    if (url.indexOf('?') >= 0) {
      var params = url.substring((url.indexOf('?') + 1), url.length)
      this.paramsHref = params
      if (params !== null && params !== undefined && params !== '') {
        const paramArr = params.split('&')
        for (const index in paramArr) {
          const key = paramArr[index].split('=')[0]
          const val = paramArr[index].split('=')[1]
          console.log(val)
          switch (key) {
            case 'isApprentice':
              this.isApprentice = val
              break
            case 'scheme':
              this.scheme = decodeURIComponent(val)
              break
            case 'picName':
              this.picName = val
              break
            case 'package_name':
              this.package = val
              break
            default:
              break
          }
        }
      }
    }
    console.log('scheme', this.scheme, this.picName)
    if ((this.package.includes('skin') || this.package.includes('wifi')) && !this.picName) {
      const items = this.package.substring((this.package.lastIndexOf('.') + 1), url.length)
      this.picName = items
    } else if (this.package.includes('sesame')) {
      const items = this.package.substring((this.package.lastIndexOf('.') + 1), url.length)
      this.picName = items
    }
    // this.gotoApk(channel, inviteCode, packageName, userId, versionCode, scheme)
  },
  mounted () {
    console.log('process.env.ENV', process.env.ENV)
  },
  methods: {
    gotoApk () {
      let paramsHref = ''
      if (!this.scheme || this.scheme === '') {
        paramsHref = this.paramsHref
        this.scheme = 'charge://' + this.package
      } else {
        if (this.paramsHref.indexOf('scheme') !== -1) {
          paramsHref = this.paramsHref.substring(0, (this.paramsHref.indexOf('scheme') - 1))
        } else {
          paramsHref = this.paramsHref
        }
      }
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        // window.location.href = this.apk
        if (this.getDeviceType() === 'android') {
          const iframes = document.createElement('iframe')
          iframes.src = this.scheme
          // iframes.setAttribute('style', 'display:none;')
          iframes.setAttribute('height', '0rem')
          iframes.setAttribute('width', '0rem')
          iframes.setAttribute('frameborder', '0')
          document.body.appendChild(iframes)
          console.log(iframes)
          this.loading = true

          console.log('paramsHref', paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            // const htp = 'https://mtasks.dev.tagtic.cn/'
            const htp = 'https://ad-static-xg.tagtic.cn/'
            window.location.href = htp + 'mtasks/api/apk/pack/' + paramsHref
          }, 5000)
        }
        if (this.getDeviceType() === 'ios') {
          this.loading = true
          const scheme = this.package.split('.')
          console.log(scheme[scheme.length - 1])
          window.location.href = scheme[scheme.length - 1] + '://' + this.package
          this.timers = setTimeout(() => {
            this.loading = false
            const hrf = this.package === 'com.sesame.happyplanet' ? 'https://apps.apple.com/cn/app/id1578449597' : ''
            window.location.href = hrf
          }, 5000)
        }

        this.detectionOut()
      }
    },
    detectionOut () {
      document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden
        if (isHidden) {
          // 离开页面
          console.log('==离开页面==')
        } else {
          // 进入页面
          clearTimeout(this.timers)
          this.loading = false
        }
      })
    },
    tackBack () {
      if (this.picName !== '') {
        return { backgroundImage: 'url(https://ad-static-xg.tagtic.cn/' + this.imgBaseUrl + '/SDKL666-' + this.picName + '-1.png)' }
      } else {
        // return { backgroundImage: 'url(../../static/recharge-hbsm-1.png)' }
      }
    },
    getDeviceType () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    }
  }
}
</script>
<style lang="scss" sscoped="scoped">

.shareAct {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../static/img/shareActbg.png') no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: .94rem;
  text-align: center;
  .logo {
    text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
    img{
      width: 1.98rem;
      height: 2.42rem;
      margin: .78rem auto .3rem;
    }
  }
  .imgs {
    margin-bottom: .74rem;
    &.h800 {
      height: 4.3rem;
    }
    &.hnone{
      display: none;
    }
    img {
      width: 5.6rem;
      height: 3.9rem;
    }
  }
  .btn {
    width: 5.1rem;
    height: 1.1rem;
    background: linear-gradient(180deg, #FFE9B0 0%, #FFBF1E 100%);
    border-radius: .55rem;
    margin:0 auto;
    font-size: .42rem;
    font-weight: 500;
    color: #A95208;
    line-height: 1.1rem;
    &.btn-style {
      width: 4.36rem;
      height: 1.40rem;
      background: url('../../static/img/share-btn.png') center no-repeat;
      background-size: 100% 100%;
      line-height: 1.3rem;
      color: #fff;
      font-size: .44rem;
      font-family: YouSheBiaoTiHei;
      letter-spacing: 1px;
    }
     &.btn-crazy {
      width: 4.36rem;
      height: 1.40rem;
      background: url('../../static/img/share-btn.png') center no-repeat;
      background-size: 100% 100%;
      line-height: 1.3rem;
      color: #fff;
      font-size: .44rem;
      font-family: YouSheBiaoTiHei;
      letter-spacing: 1px;
      margin-top: 7.1rem;
    }
    &.btn-style1 {
      width: 4.36rem;
      height: 1.80rem;
      background: url('../../static/img/invitation-btn.png') center no-repeat;
      background-size: 100% 100%;
      line-height: 1.7rem;
      color: #441300;
      font-size: .44rem;
      font-family: YouSheBiaoTiHei;
      letter-spacing: 1px;
      font-weight: bold;
    }
    &.mt200 {
      position: fixed;
      bottom: 1.4rem;
      left: 50%;
      transform: translateX(-50%);
      width: 5.64rem;
      height: 1.22rem;
      line-height: 1.22rem;
      border-radius: 0rem;
    }
    &.btn-happy{
      background: url(../../static/img/btn-happy.png) center no-repeat;
      background-size: 100% 100%;
      width: 4rem;
      height: 1rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.fanfan{
      background: url(../../static/img/btn-fanfan.png) center no-repeat;
      background-size: 100% 100%;
      width: 4rem;
      height: 1rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.mflp{
      background: url(../../static/img/btn-mflp.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.v10wj{
      background: url(../../static/img/btn-v10wj.png) center no-repeat;
      background-size: 100% 100%;
      width: 4rem;
      height: 1.2rem;
      line-height: 1.2rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.ttlp{
      background: url(../../static/img/btn-ttlp.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.hfdr{
      background: url(../../static/img/btn-hfdr.png) center no-repeat;
      background-size: 100% 100%;
      width: 3.4rem;
      height: 1.04rem;
      line-height: 1.04rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
     &.ttlpf{
      background: url(../../static/img/btn-ttlpf.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
     &.cjyd{
      background: url(../../static/img/btn-ttlpf.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: 7rem;
    }
     &.wzzs{
      background: url(../../static/img/wzzs.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.76rem;
      height: 1.26rem;
      line-height: 1.1rem;
      border-radius: 0;
      color: #984217;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: 7rem;
    }
    &.commonBtn{
      background: url(../../static/img/common-btn.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.76rem;
      height: 1.26rem;
      line-height: 1.26rem;
      border-radius: 0;
      color: #FFFFFF;
      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: 7rem;
    }
    &.yjhp{
      background: url(../../static/img/btn-yjhp.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.xylpf{
      background: url(../../static/img/btn-xylpf.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 6rem;
    }
    &.luckycat{
      background: url(../../static/img/btn-luckycat.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.36rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 0;
      color: #fff;
      margin-top: 7rem;
    }
    &.dswj{
      background: url(../../static/img/btn-dswj.png) center no-repeat;
      background-size: 100% 100%;
      width: 3.86rem;
      height: 1rem;
      line-height: 1rem;
      border-radius: 0;
      color: #8D4C15;
      margin-top: 7rem;
      font-weight: 700;
    }
    &.yxhz{
      background: url(../../static/img/btn-yxhz.png) center no-repeat;
      background-size: 100% 100%;
      width: 4.04rem;
      height: 1.1rem;
      line-height: 0.85rem;
      border-radius: 0;
      color: #8D4C15;
      margin-top: 7rem;
      font-weight: 700;
    }
  }
  .load {
    width: 2.8rem;
    height: 2.8rem;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .h300{
    width: 3.6rem !important;
    margin-left: .5rem !important;
    margin-top: -0.2rem !important;
    height: 3rem!important;
  }
  .w112 {
    width: 2.24rem !important;
    height: 2.42rem !important;
    margin-bottom: 0!important;
    margin-top: 0!important;
  }
  .w114 {
    width: 3.72rem !important;
    height: 2.78rem !important;
    margin-bottom: 0.64rem!important;
    margin-top: 0!important;
  }
  .w360 {
    width: 3.6rem !important;
    height: 3.36rem !important;
    margin-bottom: 0.64rem!important;
    margin-top: 0!important;
  }
  .w152 {
    width: 3.04rem !important;
    height: 2.70rem !important;
    margin-bottom: 0.64rem!important;
    margin-top: 0!important;
  }
  .w708 {
    margin-bottom: 0;
    img {
      width: 7.08rem !important;
      height: 7.08rem !important;
    }
  }
  .w248 {
      width: 2.48rem !important;
      height: 2.42rem !important;
  }
  .w327 {
      width: 3.27rem !important;
      height: 3.08rem !important;
  }
  .w594 {
    margin-bottom: 1.57rem;
    img {
      width: 5.94rem !important;
      height: 5.3rem !important;
    }
  }
  .w327 {
      width: 3.27rem !important;
      height: 3.08rem !important;
  }
  .w180{
    width: 3.6rem !important;
      height: 2.7rem !important;
      margin-left: .5rem !important;
      margin-top: -0.2rem !important;
  }
  .w186{
    width: 3.72rem !important;
    height: 3.12rem !important;
    margin-top: 0rem !important;
  }
  .w188{
    width: 3.76rem !important;
    height: 3.48rem !important;
    margin-top: 0rem !important;
  }
  .h168{
    width: 3.6rem !important;
    height: 3.36rem !important;
    margin-top: 0 !important;
  }
  .w225{
    width: 4.5rem !important;
    height: 3.12rem !important;
  }

}
</style>
