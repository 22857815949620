import Vue from 'vue'
import { NoticeBar, Swipe, SwipeItem } from 'vant'

import 'vant/lib/notice-bar/style'
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'

Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
